<template>
  <div>
    <component
      :is="resolveSectionComponent(section.__typename)"
      v-for="section in data?.index!.hero"
      :key="section.__typename"
      :data="section"
      :region="Regions.HERO"
    />
    <div v-if="Array.isArray(data?.index!.blocks) && data?.index!.blocks.length">
      <component
        :is="resolveSectionComponent(section.__typename)"
        v-for="section in data.index.blocks"
        :key="section.__typename"
        :data="section"
        :region="Regions.MAIN"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { toHead } from 'vue-datocms'
import { ModelApiKey } from '~/types/models'
import { Regions } from '~/types/components'

const { resolveSectionComponent } = useResolveComponent()

const config = useRuntimeConfig()
const route = useRoute()
const { localeDato } = useLanguage()

const { data } = await useAsyncGql('index', {
  // @ts-ignore
  locale: localeDato.value,
  options: {
    initialCache: false
  }
})

const datoHead = toHead((data.value as any).index.seo)
const i18nHeadLinks = useLocaleHeadLinks(data?.value!.index?._modelApiKey as ModelApiKey)

useHead(() => {
  const metaTags = [...(datoHead.meta || [])]

  return {
    title: datoHead.title,
    link: [...i18nHeadLinks],
    meta: [
      {
        hid: 'og:url',
        property: 'og:url',
        content: config.public.appUrl + route.fullPath
      },
      ...metaTags
    ]
  }
})
</script>
